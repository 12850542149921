// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import feather from "feather-icons"

require("bootstrap")
import 'bootstrap/js/dist/tooltip'

import "../stylesheets/application"
import Sticky from 'sticky-js'
var sticky = new Sticky('#main-navigation');

import { Tooltip, Carousel } from 'bootstrap';

Rails.start()
ActiveStorage.start()

document.addEventListener("DOMContentLoaded", function() {
    feather.replace()

    if ($('#map').length) {
        $(function(){
            console.log("loading map");
            google.maps.event.addDomListener(window, 'load', init);

            function init() {
                var mapOptions = {
                    zoom: 15,
                    scrollwheel: false,
                    draggable: !$('html').hasClass('touch'),
                    disableDefaultUI: true,
                    center: new google.maps.LatLng(47.6716537, 9.1589777),
                    styles: [{
                        featureType: "landscape",
                        stylers: [{saturation: -100}, {lightness: 30}, {visibility: "on"}]
                    }, {
                        featureType: "poi",
                        stylers: [{saturation: -100}, {lightness: 51}, {visibility: "simplified"}]
                    }, {
                        featureType: "road.highway",
                        stylers: [{saturation: -100}, {visibility: "simplified"}]
                    }, {
                        featureType: "road.arterial",
                        stylers: [{saturation: -100}, {lightness: 30}, {visibility: "on"}]
                    }, {
                        featureType: "road.local",
                        stylers: [{saturation: -100}, {lightness: 40}, {visibility: "on"}]
                    }, {
                        featureType: "transit",
                        stylers: [{saturation: -100}, {visibility: "simplified"}]
                    }, {
                        featureType: "administrative.province",
                        stylers: [{visibility: "off"}]/**/
                    }, {
                        featureType: "administrative.locality",
                        stylers: [{visibility: "off"}]
                    }, {featureType: "administrative.neighborhood", stylers: [{visibility: "on"}]/**/}, {
                        featureType: "water",
                        elementType: "labels",
                        stylers: [{visibility: "on"}, {lightness: -25}, {saturation: -100}]
                    }, {
                        featureType: "water",
                        elementType: "geometry",
                        stylers: [{hue: "#ffff00"}, {lightness: -25}, {saturation: -97}]
                    }]
                };

                // Get the HTML DOM element that will contain your map
                // We are using a div with id="map" seen below in the <body>
                var mapElement = document.getElementById('map');

                // Create the Google Map using out element and options defined above
                var map = new google.maps.Map(mapElement, mapOptions);

                var marker = new google.maps.Marker({
                        position: new google.maps.LatLng(47.6716537,9.1589777),
                        icon: new google.maps.MarkerImage($('#map').data('marker'),
                        null, null, null, new google.maps.Size(42, 63)),
                    draggable: false,
                    map: map
            });

                setTimeout(function () {
                    $('#map').append("<div class='mapoverlay'></div>");
                }, 1000);
            }
        });
    }

    var siteMenuClone = function() {

        $('.js-clone-nav').each(function() {
            var $this = $(this);
            $this.clone().attr('class', 'site-nav-wrap').appendTo('.site-mobile-menu-body');
        });


        setTimeout(function() {

            var counter = 0;
            $('.site-mobile-menu .has-children').each(function(){
                var $this = $(this);

                $this.prepend('<span class="arrow-collapse collapsed">');

                $this.find('.arrow-collapse').attr({
                    'data-toggle' : 'collapse',
                    'data-target' : '#collapseItem' + counter,
                });

                $this.find('> ul').attr({
                    'class' : 'collapse',
                    'id' : 'collapseItem' + counter,
                });

                counter++;

            });

        }, 1000);

        $('body').on('click', '.arrow-collapse', function(e) {
            var $this = $(this);
            if ( $this.closest('li').find('.collapse').hasClass('show') ) {
                $this.removeClass('active');
            } else {
                $this.addClass('active');
            }
            e.preventDefault();

        });

        $(window).resize(function() {
            var $this = $(this),
                w = $this.width();

            if ( w > 768 ) {
                if ( $('body').hasClass('offcanvas-menu') ) {
                    $('body').removeClass('offcanvas-menu');
                }
            }
        })

        $('body').on('click', '.js-menu-toggle', function(e) {
            var $this = $(this);
            e.preventDefault();

            if ( $('body').hasClass('offcanvas-menu') ) {
                $('body').removeClass('offcanvas-menu');
                $this.removeClass('active');
            } else {
                $('body').addClass('offcanvas-menu');
                $this.addClass('active');
            }
        })

        // click outisde offcanvas
        $(document).mouseup(function(e) {
            var container = $(".site-mobile-menu");
            if (!container.is(e.target) && container.has(e.target).length === 0) {
                if ( $('body').hasClass('offcanvas-menu') ) {
                    $('body').removeClass('offcanvas-menu');
                }
            }
        });
    };
    siteMenuClone();
})